import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import Script from 'react-load-script';
import TextField from '../../../../../materialUi/textField';
import InformationIcon from '../../../../../assets/icons/information.png';

const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_API_KEY}`;

function Address(props) {
  const {
    formState,
    setValue,
    clearErrors,
    setError,
    register,
    control,
    data,
  } = props;

  const { errors } = formState;
  const options = { types: ['address'], componentRestrictions: { country: 'ca' } };
  let autocomplete;

  const handleScriptLoad = () => {
    autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), options);
    /* global google */
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  const [address, setAddress] = React.useState({
    postalCode: '',
  });

  const setAddressErrors = () => {
    setValue('address', null, { shouldValidate: true });
    setError('address', {
      type: 'invalidAddress',
      message: 'Invalid Address',
    });
  };

  const setFormAddress = (selectedAddress) => {
    if (selectedAddress.street) {
      setValue('address', selectedAddress);
      clearErrors('address');
    }
  };

  const registerCurrentAddress = (googleAddress, postalCode) => {
    const addressCopy = address;
    addressCopy.street = `${googleAddress[0].long_name} ${googleAddress[1].long_name}`;
    addressCopy.city = googleAddress[2].long_name;
    addressCopy.province = googleAddress[5].long_name;
    addressCopy.postalCode = postalCode;
    setAddress(addressCopy);
    setFormAddress(addressCopy);
  };

  const handleAddress = (event) => {
    const { value } = event.target;
    setError('address', {
      type: 'invalidAddress',
      message: 'Please start typing the street number, and select the address from the list',
    });
    setAddress(value);
  };

  const getPostalCode = (googleAddress) => {
    let result = '';
    for (let i = 0; i < googleAddress.length; i++) {
      for (let j = 0; j < googleAddress[i].types.length; j++) {
        if (googleAddress[i].types[j] === 'postal_code') {
          result = googleAddress[i].long_name;
        }
      }
    }
    return result;
  };

  const checkGoogleAddressErrors = (googleAddress) => googleAddress[0] == null
    || googleAddress[1] == null
    || googleAddress[2] == null
    || googleAddress[5] == null
    || googleAddress[6] == null;

  const getLocation = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // km (change this constant to get miles)
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.cos((lat1 * Math.PI) / 180)
        * Math.cos((lat2 * Math.PI) / 180)
        * Math.sin(dLon / 2)
        * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    if (d > 1) return Math.round(d * 1000);
    if (d <= 1) return Math.round(d * 1000);
    return d;
  };

  const determineMembershipType = (lat, long) => {
    let memberType;

    // Define latitude and longitude boundaries for the area
    const latMin = 43.640406;  // South boundary (Front St.)
    const latMax = 43.658590;  // North boundary (College St.)
    const longMin = -79.411510;  // West boundary (Euclid Ave.)
    const longMax = -79.393860;  // East boundary (Spadina Ave.)
    // Check if the location is within the boundaries
    if (lat >= latMin && lat <= latMax && long >= longMin && long <= longMax) {
        memberType = 'Community';
    } else {
        memberType = 'Associate';
    }

    return memberType;
};


  // <--- Address autocomplete validation --->
  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();
    const googleAddress = addressObject.address_components;
    if (googleAddress) {
      if (checkGoogleAddressErrors(googleAddress)) {
        setAddressErrors();
      } else {
        register('membershipType');
        setValue('membershipType', determineMembershipType(
          addressObject.geometry.location.lat(),
          addressObject.geometry.location.lng(),
        ));
        const postalCode = getPostalCode(googleAddress);
        registerCurrentAddress(googleAddress, postalCode);
      }
    } else {
      setAddressErrors();
    }
  };

  const handleApartment = (value) => {
    if (typeof address === 'object') {
      const addressCopy = address;
      addressCopy.apt = value;
      setFormAddress(addressCopy);
    }
  };

  React.useEffect(() => {
    if (!data.address) {
      register('address', { required: 'Address is required' });
    } else {
      setValue('apt', data.address.apt);
      setAddress(data.address);
    }
  }, [data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="registration-form-sub-title address-title-mobile">
        {data.membershipCategory === 'Family'
          ? 'Family Home Address' : 'Home Address'}
      </Grid>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <Grid item xs={12} lg>
        <TextField
          label="Current Address"
          id="autocomplete"
          name="address"
          value={(address.street) ? `${address.street}, ${address.city}, ${address.province}` : null}
          handleChanges={handleAddress}
          helperText={errors.address && errors.address.message}
          error={!!errors.address}
          readOnly={false}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={2}>
        <Controller
          name="apt"
          control={control}
          defaultValue=""
          rules={{
            maxLength: { value: 20, message: 'Max length 20' },
            validate: (value) => handleApartment(value),
          }}
          render={({ field }) => (
            <TextField
              label="Apt#"
              id="apt#"
              name="Apt Number"
              helperText={errors.apt && errors.apt.message}
              error={!!errors.apt}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={4} xl={3}>
        <TextField
          label="Postal Code"
          id="postalCode"
          name="Postal Code"
          value={address.postalCode}
          handleChanges={() => {}}
          helperText=""
          error={false}
          readOnly
        />
      </Grid>
      <Grid item xs={12}>
        <span className="register-address-text-highlight">*Address Verification Required.&nbsp;</span>
          Provide proof of address  to reception (eg. valid ID or utility bill).&nbsp;
        <img className="icon" src={InformationIcon} alt="Question mark" />
        <hr />
      </Grid>
    </Grid>
  );
}

Address.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  formState: PropTypes.shape({
    errors: PropTypes.any,
  }).isRequired,
  setValue: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  data: PropTypes.shape(
    {
      membershipCategory: PropTypes.string,
      address: PropTypes.shape(
        {
          postalCode: PropTypes.string,
        },
      ),
    },
  ).isRequired,
};


export default Address;
